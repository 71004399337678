import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import './Sales.css';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  gallery: {
    display:'flex',
    flexFlow: 'row wrap'
  },
  galleryItem: {
    margin: '5px',
    border: '1px solid #ccc',
    width: 'calc( (100% ) - 20px)',
    '& img': {
      width: '100%',
      height: 'auto'
    },
    [theme.breakpoints.up(400)]: {
      width: 'calc( (100% / 2) - 20px)',
    },
    [theme.breakpoints.up(700)]: {
      width: 'calc( (100% / 3) - 20px)',
    }
  },
  desc: {
    padding: '15px',
    textAlign: 'center'
  },
  arrow: {
    listStyle: 'none',
    '& li': {
      textIndent: '-1.3em'
    },
    '& li:before': {
      content: "'🡲 '"
    }
  },
  star: {
    listStyle: 'none',
    '& li': {
      textIndent: '-1.3em'
    },
    '& li:before': {
      content: "'★ '"
    }
  },
  square: {
    listStyle: 'square',
  },
  page: {
    fontFamily: 'Lora, Poppins, Helvetica, sans-serif',
    fontSize: '1.25em',
    fontWeight: '500',
    letterSpacing: '.05em',
    textSizeAdjust: '100%',
    maxWidth: '50em',
    margin: '0 auto',
    color: 'black',
    [theme.breakpoints.up(700)]: {
      fontSize: '1.75em',
    }
  },
  box: {
    backgroundColor: '#202',
    padding: '1em',
    fontFamily: 'Lora, Poppins, Helvetica, sans-serif',
    background: "url('SalesBackground.jpg')",
    backgroundAttachment: "fixed",
    color: 'white',
    boxShadow: 'inset 0px 0px 0px 5px #003, inset 0px 0px 0px 6px #fff',
    [theme.breakpoints.up(700)]: {
      boxShadow: 'inset 0px 0px 0px 10px #003, inset 0px 0px 0px 12px #fff'
    }
  },
  small: {
    fontSize: '.75em',
  },
  article: {
  },
  info: {
    border: '1px solid black',
    marginLeft: '1em',
    padding: '1em',
    width: '10em',
    float: 'right',
    backgroundColor: '#202',
    background: "url('SalesBackground.jpg')",
    backgroundAttachment: "fixed",
    color: 'white',
    boxShadow: 'inset 0px 0px 0px 10px #003, inset 0px 0px 0px 12px #fff',
    [theme.breakpoints.up(1000)]: {
      width: '15em',
    },
  },
  whiteBox: {
    backgroundColor: '#fff8',
    padding: '.5em',
    fontFamily: 'Lora, Poppins, Helvetica, sans-serif',
    color: 'black',
    [theme.breakpoints.up(700)]: {
      padding: '1em',
    },
  },
  onlyLarge: {
    display: 'none',
    [theme.breakpoints.up(1000)]: {
      display: 'block',
    }
  },
  notLarge: {
    display: 'block',
    [theme.breakpoints.up(1000)]: {
      display: 'none',
    }
  },
  altFont: {
    fontFamily: '"Petit Formal Script", cursive',
    lineHeight: '1.1em',
  },
  full: {
    padding: '.25em',
    textAlign: 'left',
    [theme.breakpoints.up(1000)]: {
      textAlign: 'justify',
    },
  },
  left: {
    padding: '.25em',
    textAlign: 'left'
  },
  spacer: {
    padding: '.25em',
    [theme.breakpoints.up(700)]: {
      padding: '1em',
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: '700',
  },
  underline: {
    textDecoration: 'underline',
  },
  text: {
    textAlign: 'left',
    paddingTop: '1em',
    paddingLeft: '1em',
  },

  button:
  {
    color: 'darkblue',
    backgroundColor: 'orange',
    fontWeight: '700',
    fontSize: '1.5em',
    '&:hover': {
      backgroundColor: 'darkgoldenrod',
    }
  },
  footer:
  {
    fontFamily: 'Lora, Poppins, Helvetica, sans-serif',
    fontSize: '1em',
    marginTop: '1.25em',
    marginBottom: '.25em',
    overflowWrap: 'break-word',
    padding: '0',
    textSizeAdjust: '100%',
    height: '3em',
    width: '100%',
    paddingLeft: '1em',
    paddingRight: '1em',
  }
}));

export function Sales2() {
  document.body.style.backgroundImage = "url(/Background.seamless.jpg)";
  document.body.style.backgroundSize = "auto";
  const classes = useStyles();
  let groupLink = "https://alison-p-brown.square.site/product/Transforming-Motherhood/3?cs=true";

  const handleJoin = (event) => {
    event.preventDefault()
    window.location.href = groupLink;
  };

  return (
    <div className={classes.page}>
      <div className={classes.box}>
        <h1 className={clsx(classes.full, classes.italic)}>Transforming Motherhood</h1>
        <div className={classes.full}>Create a Life You Love by Unleashing the Power of Your Emotions - So That Your Home Becomes a Joy-filled Launch Pad for the Amazing Lives You and Your Children Will Lead</div>
        <div className={classes.full}></div>
        <div className={classes.center}><Button href={groupLink}
          onClick={handleJoin}
          variant="contained"
          color="primary"
          className={classes.button}>Sign me up!</Button></div>
      </div>
      <div className={classes.spacer}></div>
      <div className={classes.whiteBox}>
        <div className={classes.full}>To the mom who loves her kids so much it hurts:</div>
        <div className={classes.full}></div>
        <ul>
          <li className={classes.full}>Do you lay awake at night feeling guilty about how the time with your kids has gone?</li>
          <li className={classes.full}>Do you find yourself stuck in worries and anxious thoughts that stop you from enjoying time with your kids?</li>
          <li className={classes.full}>Are you overwhelmed by all the things you have to do and feel like you have no time left to spend with your kids?</li>
          <li className={classes.full}>Do you want to enjoy your kids’ childhoods, but it seems impossible?</li>
        </ul>
        <div className={classes.full}>You’ve tried everything:</div>
        <ul className={classes.square}>
          <li className={classes.full}>I have so many things dragging me in all directions. I feel tired and overwhelmed.</li>
          <li className={classes.full}>My kids disrespect me and don’t listen. I get triggered, I’ve had enough, and I just lose it. Then I lay awake at night feeling guilty for being such a bad mom.</li>
          <li className={classes.full}>I’m always distracted by having a million things to do.</li>
          <li className={classes.full}>I need to put the phone down and interact with my kids - but I don’t.</li>
          <li className={classes.full}>I can’t get out of my head and focus on my kids.</li>
        </ul>
        <div className={classes.full}>But even if they help sometimes, you still aren’t having the mothering experience you dreamed of.</div>
        <div className={classes.full}></div>
        <div className={classes.full}>Moms everywhere are exhausted, burned out, and just barely scraping by…</div>
        <div className={classes.full}></div>
        <div className={classes.full}>…and our kids are feeling lost and confused as we withdraw from them to try and keep our own heads above water.</div>
        <div className={classes.full}></div>
        <div className={classes.full}>Motherhood is hard. We all know that.</div>
        <div className={classes.full}></div>
        <div className={classes.full}>But each of us was born with the power we need to do hard things and still feel good.</div>
        <div className={classes.full}></div>
        <div className={classes.full}>Unfortunately, our society has hidden that power from us.</div>
        <div className={classes.full}></div>
        <div className={classes.full}>Until now…</div>
        <div className={classes.full}></div>
        <div className={classes.full}>Imagine feeling happy, present, and playful in the little moments with your kids:</div>
        <ul className={classes.star}>
          <li className={classes.full}>Swinging the toddler around when you get her down</li>
          <li className={classes.full}>Tickling the baby after the diaper change</li>
          <li className={classes.full}>Laughing with your kids so hard you snort</li>
          <li className={classes.full}>Cuddling together and sharing one of your favorite movies with your kids</li>
        </ul>
        <div className={classes.full}></div>
        <div className={classes.full}>And imagine feeling completely calm and confident when you’re teaching and leading your kids:</div>
        <ul className={classes.star}>
          <li className={classes.full}>Correcting poor behavior</li>
          <li className={classes.full}>Connecting when they’re overwhelmed by emotion</li>
          <li className={classes.full}>Helping them be prepared for life</li>
        </ul>
        <div className={classes.full}></div>
        <div className={classes.full}>This is totally within your reach, and I want to help you get there!</div>
      </div>
      <div className={classes.spacer}></div>
      <div className={classes.box}>
        <div>Presenting...</div>
        <h1 className={classes.italic}>Transforming Motherhood</h1>
        <h3>The ONLY personal development mentoring program based on emotional intelligence practices for moms who are ready to make their lives match their dreams</h3>
        <div className={classes.full}></div>
        <div className={classes.center}><Button href={groupLink}
          onClick={handleJoin}
          variant="contained"
          color="primary"
          className={classes.button}>Enroll Now!</Button></div>
      </div>
      <div className={classes.spacer}></div>
      <div className={classes.whiteBox}>
        <div className={classes.article}>
          <div className={classes.full}>If you…</div>
          <ul>
            <li className={classes.full}>Know you love your kids and want to enjoy being around them more</li>
            <li className={classes.full}>Are working on your anxiety, depression, or low mood, but not learning the skills you need to honor and work with your emotional responses</li>
            <li className={classes.full}>Have occasional days when everything just works and parenting is great, and then have days when everything seems impossible… then Transforming Motherhood is the place for you!</li>
          </ul>
          <div className={classes.full}></div>
          <div className={classes.full}>Learn how to use my complete Power Emotions system make your whole emotional menagerie into your strongest allies:</div>
          <div className={classes.full}></div>
          <div className={classes.gallery}>
            <div className={classes.galleryItem}>
              <img src="dragon.png" alt="Anger Dragon" width="215" height="215" />
              <div className={classes.desc}>Anger Dragon: Frustration, Impatience, Annoyance → Empowerment</div>
            </div>
            <div className={classes.galleryItem}>
              <img src="bear.png" alt="Sadness Bear" width="215" height="215" />
              <div className={classes.desc}>Sadness Bear: Grief → Peace</div>
            </div>
            <div className={classes.galleryItem}>
              <img src="owl.png" alt="Fear Owl" width="215" height="215" />
              <div className={classes.desc}>Fear Owl: Worry, Anxiety, Nervousness → Intuition and Confidence</div>
            </div>
            <div className={classes.galleryItem}>
              <img src="dog.png" alt="Shame Dog" width="215" height="215" />
              <div className={classes.desc}>Shame Dog: Blame, Guilt, Embarrassment → Pride and Self-Love</div>
            </div>
            <div className={classes.galleryItem}>
              <img src="turtle.png" alt="Boredom Turtle" width="215" height="215" />
              <div className={classes.desc}>Boredom Turtle: Apathy, Overwhelm, Restlessness, Lack of Motivation → Self-Care and Support</div>
            </div>
            <div className={classes.galleryItem}>
              <img src="monkey.png" alt="Envy Monkey" width="215" height="215" />
              <div className={classes.desc}>Envy Monkey: Jealousy, Bitterness, Despair → Inspiration</div>
            </div>
          </div>
          <div className={classes.full}></div>
          <div className={classes.full}>With their help, you have the power to create a life you love and parent from your best self.</div>
        </div>
      </div>
      <div className={classes.spacer}></div>
      <div className={classes.box}>
        <h3 className={clsx(classes.center)}>What’s Inside Transforming Motherhood:</h3>
        <h4 className={clsx(classes.full, classes.bold)}>Module 1: How to Train Your Emotional Menagerie</h4>
        <div className={clsx(classes.full, classes.italic)}>We start with learning about each of the Power Emotions, exactly how they’re trying to help you, and specific techniques you can use to access their power instead of letting them mess up your life. Most parenting and self-development programs skip this step, but it’s essential to be dialed in to your emotional power before you try to make changes if you want them to stick.</div>
        <div className={classes.full}></div>
        <h4 className={clsx(classes.full, classes.bold)}>Module 2: Resilient Motivation</h4>
        <div className={clsx(classes.full, classes.italic)}>Changing the way we deal with the world is not easy. We won’t be able to step into our best selves by beating ourselves up. In Module 2, you’ll create specific systems to support yourself through the transformations you want to make, making a sustainable change that will last forever.</div>
        <div className={classes.full}></div>
        <h4 className={clsx(classes.full, classes.bold)}>Module 3: Parenting Heart to Heart</h4>
        <div className={clsx(classes.full, classes.italic)}>Once we’ve worked on our own issues, we can begin to help others have the same transformation. In Module 3, you’ll learn the exact processes to use in your home to honor your kids’ emotions and teach them to access their own power, so that they can be empowered to grow into their best selves too.</div>
        <div className={classes.full}></div>
        <h4 className={clsx(classes.full, classes.bold)}>Module 4: Lead from the Heart</h4>
        <div className={clsx(classes.full, classes.italic)}>The pinnacle of emotional intelligence is leadership: using our own power and our understanding of others to encourage them to do things for the good of all. Module 4 covers the best practices for loving leadership in the home, allowing everyone in your family to develop into their best selves and help each other and the world.</div>
        <div className={classes.full}></div>
      </div>
      <div className={classes.spacer}></div>
      <div className={classes.whiteBox}>
        <div className={classes.article}>
          <h3>What It Looks Like:</h3>
          <div className={classes.full}>I get it. You’re a busy mom. No matter what transformation it promises, you don’t have time for hour-long video trainings and marathon journal sessions investigating your feelings.</div>
          <div className={classes.full}></div>
          <div className={classes.full}>Transforming Motherhood is designed for moms like you and me.</div>
          <div className={classes.full}></div>
          <div className={classes.full}>You get a daily 5-10 minute training, in video, audio, and written form, sent to your email and posted in the private Facebook group.</div>
          <div className={classes.full}></div>
          <div className={classes.full}>The trainings give you a tiny action to take that day, so that you can put what you’re learning into practice immediately.</div>
          <div className={classes.full}></div>
          <div className={classes.full}>Once a week we get on a group call and I work through the more complex techniques with you directly. I also coach everyone through questions about how their Power Emotions have been showing up in the past week.</div>
          <div className={classes.full}></div>
          <div className={classes.full}>There are systems and times built in for implementation and review, so that you can form new habits and have them actually stick.</div>
          <div className={classes.full}></div>

        </div>
      </div>
      <div className={classes.spacer}></div>
      <div className={classes.box}>
        <h3>What You Get:</h3>
        <div className={classes.full}></div>
        <ul className={classes.arrow}>
          <li className={classes.full}>16 weeks of daily training in emotional intelligence skills for moms</li>
          <li className={classes.full}>16 group coaching calls to workshop new skills and get answers to any questions</li>
          <li className={classes.full}>Access to the private Facebook group for community and support so that you never stay stuck</li>
        </ul>
        <div className={classes.full}>Plus BONUSES:</div>
        <ul className={classes.arrow}>
          <li className={classes.full}>Printable visual toolkit to help you remember what you’ve learned</li>
          <li className={classes.full}>Weekly self-assessment to celebrate your progress and keep you from getting stuck</li>
          <li className={classes.full}>6 recorded guided meditation/affirmation tracks by a certified hypnotherapist</li>
          <li className={classes.full}>Beta access to the Feeling Friends course so that you can teach your kids how to use their Power Emotions</li>
          <li className={classes.full}>Full access for your parenting partner - and a 15 minute summary video if they don’t want to put in as much time as you do :)</li>
        </ul>
        <div className={classes.spacer}></div>
        <div className={classes.full}></div>
        <div className={classes.full}></div>
      </div>
      <div className={classes.spacer}></div>
      <div className={classes.whiteBox}>
        <div className={classes.article}>

          <h2>TOTAL INVESTMENT: $997</h2>
          <h3>Or Four Payments of $297</h3>
          <div className={classes.full}></div>
          <div className={classes.center}><Button href={groupLink}
            onClick={handleJoin}
            variant="contained"
            color="primary"
            className={classes.button}>Get Unlimited Access!</Button></div>
        </div></div>
      <div className={classes.spacer}></div>
      <div className={classes.box}>
        <div className={clsx(classes.full, classes.altFont)}>At the end of our four months together, you'll have built the emotional foundation that will make you unstoppable.</div>
        <div className={classes.full}></div>
        <div className={clsx(classes.full, classes.altFont)}>When you know how to rely on your Power Emotions, you will dare to shine brightly and reach for the stars - and you will support your kids in the same endeavor.</div>
        <div className={classes.full}></div>
        <div className={clsx(classes.full, classes.altFont)}>I believe that you are amazing. And when you live your life boldly and powerfully, you make the world a better place.</div>
        <div className={classes.full}></div>
        <div className={clsx(classes.full, classes.altFont)}>I want to live in that world. Do you?</div>
        <div className={classes.full}></div>
        <div className={clsx(classes.full, classes.altFont)}>Alison</div>
      </div>
    </div>);
}
